import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card mb-2" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "card-subtitle mb-2 text-muted" }
const _hoisted_5 = ["width", "height"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h5", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _createElementVNode("h6", _hoisted_4, "Current value: " + _toDisplayString(_ctx.currentValue), 1),
      _createElementVNode("canvas", {
        ref: "canvasref",
        width: _ctx.canvasWidth,
        height: _ctx.canvasHeight
      }, null, 8, _hoisted_5)
    ])
  ]))
}