
import { counter, increment } from "../platter-hid/platter";

import { defineComponent } from "vue";

export default defineComponent({
  name: "HelloWorld",
  props: {
    msg: String
  }
});
