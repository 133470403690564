
import * as platter from "./platter-hid/platter";
import { defineComponent } from "vue";
import PlatterDisplay from "@/components/PlatterDisplay.vue";
import HelloWorld from "./components/HelloWorld.vue";
import Editor from "@/components/Editor.vue";
import Lessons from "@/components/Lessons.vue";
import PlatterLineChart from "@/components/PlatterLineChart.vue";

import { connected, setLEDColor } from "./platter-hid/platter";

const NUM_LESSONS = 8;
const LESSON_PATH = "/lessons/";
declare const window: any;
window.BOTTOM_SENSOR = 0;
window.TOP_SENSOR = 0;

export default defineComponent({
  name: "App",
  components: {
    PlatterDisplay,
    HelloWorld,
    Editor,
    Lessons,
    PlatterLineChart
  },
  data: function () {
    return {
      connected: false,
      currentLessonIndex: -1,
      lessonMarkdown: "### Welcome! Click the \"Next\" button above to view the available lessons.",
      sequence: 0,
      topSensorX: 0,
      topSensorY: 0,
      bottomSensorX: 0,
      bottomSensorY: 0,
      batteryVolt: 0,
      batteryCurrent: 0,
      proxMSB: 0,
      prox2nd: 0,
      proxLSB: 0,
      topChartData: [] as number[],
      bottomChartData: [] as number[]
    };
  },
  computed: {
    batteryPercentage (): number {
      return Math.round((this.batteryVolt / 57.5) / 4.156521739 * 100.0);
    },
    batteryClass () {
      if (this.batteryPercentage <= 10) {
        return "bg-success";
      } else if (this.batteryPercentage <= 30) {
        return "bg-warning";
      } else {
        return "bg-success";
      }
    }
  },
  connectionIcon () {
    return "";
  },
  methods: {
    togglePlatterConnection: async function (event: Event) {
      if (this.connected) {
        console.log("disconnecting...");
        // platter.setDataCallback(null);
        await platter.disconnect();
        this.connected = platter.connected;
      } else {
        console.log("connecting...");
        platter.setDataCallback(this.dataReceived);
        await platter.connect();
        await platter.ledHardReset();
        this.connected = platter.connected;
      }
    },
    hardResetLED: async function (event: Event) {
      console.log("hard reset");
      await platter.ledHardReset();
    },
    startLED: async function (event: Event) {
      console.log("start LED");
      await platter.ledStart();
    },
    setLEDColor: async function (red: boolean, green: boolean, blue: boolean) {
      console.log("set led color");
      platter.setLEDColor(red, green, blue);
      await platter.executeLEDAnimationBuffer();
    },
    setLEDAnimation: async function () {
      console.log("set led animation");
      await platter.setLEDAnimation();

      setTimeout(async function () { await platter.ledStart(); }, 100);
    },
    dataReceived: function (sequence: number, topX: number, topY: number, bottomX: number, bottomY: number, batteryVolt: number, batteryCur: number, proxMSB: number, prox2nd: number, proxLSB: number) {
      this.sequence = sequence;
      this.topSensorX = topX;
      this.topSensorY = topY;
      this.bottomSensorX = bottomX;
      this.bottomSensorY = bottomY;
      this.batteryVolt = batteryVolt;
      this.batteryCurrent = batteryCur;
      this.proxMSB = proxMSB;
      this.prox2nd = prox2nd;
      this.proxLSB = proxLSB;

      window.BOTTOM_SENSOR = bottomY;
      window.TOP_SENSOR = topY;

      const tempTopChartData = this.topChartData;
      tempTopChartData.push(topY);
      if (tempTopChartData.length > 500) {
        tempTopChartData.shift();
      }
      this.topChartData = tempTopChartData;

      const tempBottomChartData = this.bottomChartData;
      tempBottomChartData.push(bottomY);
      if (tempBottomChartData.length > 500) {
        tempBottomChartData.shift();
      }
      this.bottomChartData = tempBottomChartData;

      // TODO: add tonearm data as a constant, and as a chart
      // TODO: consider combining the top and bottom data into a single chart
      // TODO: don't let cards get narrower than canvas for chart data displays
      // TODO: add no-cache headers to curriculum md files

      // LESSONS:
      // TODO: Start on lesson where animation is calculated based on speed of platter. Keep a single or a few leds in a single position while the platter is spinning)

      // TODO: email Rob to ask what is involved in getting access to accel/gyro data
      // TODO: review lesson 1 or 2 with the run toggle on, to see if the behavior is glitchy (LED cycles on and off)

      // TODO: spin the platter based on values
      // TODO: add a ring around vinyl for clear platter with LED dots
      // TODO: can the animations be used in Lesson 2 based on conditions/thresholds
      // TODO: an API browser? or at least API docs to list our functions / color constants
      // TODO: extract the platter library into a separate module/library so it can be used in the scratch playground
      // TODO: show the number of bytes we are sending, and how many bytes are left
    },
    navigateLesson: function (incrementer: number) {
      this.currentLessonIndex += incrementer;
      if (this.currentLessonIndex >= NUM_LESSONS) {
        this.currentLessonIndex = 0;
      }
      if (this.currentLessonIndex < 0) {
        this.currentLessonIndex = NUM_LESSONS - 1;
      }

      fetch(`${LESSON_PATH}lesson-${this.currentLessonIndex + 1}.md`)
        .then(response => {
          return response.text();
        })
        .then(text => {
          this.lessonMarkdown = text;
        });
    }
  }
});

