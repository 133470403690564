
import * as monaco from "monaco-editor";
import { defineComponent, onMounted } from "vue";
import * as platter from "../platter-hid/platter";
import { LED, LEDMessageComponent } from "../platter-hid/platter";

interface ConsoleMessage {
  message: string,
  type: "error" | "warning" | "normal"
}

var studentCode = "";

const EditorComponent = defineComponent({
  name: "Editor",
  props: ["currentLessonIndex", "bottomSensor", "topSensor"],
  mounted () {
    window.vm.editorComponent = this;
  },
  created () {
    this.$watch(
      () => this.bottomSensor.toString() + this.topSensor.toString(),
      (newVal: string, oldVal: string) => {
        if (!this.runOnce) {
          window.evalCode(studentCode);
        }
      }
    );
  },
  data: function () {
    return {
      consoleMessages: [] as ConsoleMessage[],
      runOnce: true
    };
  },
  methods: {
    log (message: string, type: "error" | "warning" | "normal", data = null) {
      if (data) {
        message += " " + JSON.stringify(data);
      }
      this.consoleMessages.push({ message, type });
    },
    clearConsole () {
      this.consoleMessages = [];
    },
    toggleRunFrequency () {
      this.runOnce = !this.runOnce;
    }
  },
  setup () {
    let codeEditor: monaco.editor.IStandaloneCodeEditor;
    function initEditor () {
      // compiler options
      monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
        // target: monaco.languages.typescript.ScriptTarget.ES6,
        allowNonTsExtensions: true,
        noLib: true
      });

      var colorLibSource = [
        "declare class LED {",
        "",
        "}",
        "declare class Color {",
        "   static readonly RED:LED",
        "   static readonly BLUE:LED",
        "   static readonly GREEN:LED",
        "   static readonly WHITE:LED",
        "   static readonly PURPLE:LED",
        "   static readonly ORANGE:LED",
        "   static readonly OFF:LED",
        "}",
        "declare class LEDMessageComponent {",
        "color: LED,",
        "locations: number[],",
        "}",
        "function setLEDColor(color:LED);",
        "function setLEDColor(color:LED, location: number);",
        "function addLEDAnimation (color: LED, duration: number);",
        "function addLEDAnimation (color: LED, duration: number, ledLocations: []);",
        "function createLEDComponent (color: LED, ledLocations: []): LEDMessageComponent",
        "function addLEDAnimationMC (ledComponents: LEDMessageComponent[], duration: number)"
      ].join("\n");
      var colorLibURI = "ts:filename/color.d.ts";
      monaco.languages.typescript.javascriptDefaults.addExtraLib(colorLibSource, colorLibURI);
      let studentCode;
      if (localStorage.code) {
        studentCode = localStorage.code;
      }
      codeEditor = monaco.editor.create(document.getElementById("editor-section")!, {
        value: studentCode,
        language: "javascript"
        // theme: "vs-dark"
      });
      codeEditor.onDidChangeModelContent((event) => {
        localStorage.code = codeEditor.getValue();
      });
    }
    class Color {
      static readonly RED = { red: true, green: false, blue: false };
      static readonly BLUE = { red: false, green: false, blue: true };
      static readonly GREEN = { red: false, green: true, blue: false };
      static readonly WHITE = { red: true, green: true, blue: true };
      static readonly PURPLE = { red: true, green: false, blue: true };
      static readonly ORANGE = { red: true, green: true, blue: false };
      static readonly OFF = { red: false, green: false, blue: false };
    }
    function setLEDColor ({ red = false, green = false, blue = false }: LED, ledLocation = 0) {
      console.log("set led color");
      platter.setLEDColor(red, green, blue, ledLocation);
    }

    async function addLEDAnimation (color: LED, duration: number, ledLocations: number[] = [...Array(33).keys()]) {
      await platter.addLEDAnimationStep(color, duration, ledLocations);
    }

    async function addLEDAnimationMC (ledComponents: LEDMessageComponent[], duration: number) {
      await platter.addMultiColorLEDAnimationStep(ledComponents, duration);
    }

    async function startLED () {
      await platter.ledStart();
    }

    function createLEDComponent (color: LED, ledLocations: number[]): LEDMessageComponent {
      return { color, locations: ledLocations };
    }

    function runCode () {
      console.log("runCode");
      console.log(codeEditor.getValue());

      studentCode = codeEditor.getValue();

      window.evalCode(codeEditor.getValue());
    }

    window.evalCode = (code: string) => {
      try {
        // eslint-disable-next-line no-eval
        eval(code);
        platter.executeLEDAnimationBuffer();
      } catch (e) {
        log(e, "error");
      }
    };

    onMounted(() => {
      initEditor();
    });

    return { runCode };
  }
});

export default EditorComponent;

declare const window: any;
window.vm = {};

function log (message: string, type: "error" | "warning" | "normal", data = null) {
  window.vm.editorComponent.log(message, type, data);
}

function clearConsole () {
  window.vm.editorComponent.clearConsole();
}
