
import { defineComponent, computed, ref, toRefs, watch, onMounted } from "vue";

export default defineComponent({
  name: "PlatterLineChart",
  props: ["chartData", "currentValue", "title"],
  setup (props) {
    const canvasref = ref<InstanceType<typeof HTMLCanvasElement>>();
    const { chartData } = toRefs(props);
    const canvasWidth = ref(347);
    const canvasHeight = ref(140);

    watch(chartData, (newData, oldData) => {
      drawGraph(newData as number[]);
    }, { deep: true });

    function drawGraph (dataArr: number[]) {
      const context = canvasref!.value!.getContext("2d");
      if (!context) { return; }
      context.clearRect(0, 0, canvasWidth.value, canvasHeight.value);

      const GRAPH_TOP = 25;
      const GRAPH_BOTTOM = canvasHeight.value - 20;
      const GRAPH_LEFT = 0;
      const GRAPH_RIGHT = canvasWidth.value - 75;

      const GRAPH_HEIGHT = GRAPH_BOTTOM - 25;
      const GRAPH_WIDTH = GRAPH_RIGHT - 25;

      const arrayLen = dataArr.length;

      const largest = 256;
      // for (let i = 0; i < arrayLen; i++) {
      //   if (dataArr[i] > largest) {
      //     largest = dataArr[i];
      //   }
      // }

      context.clearRect(0, 0, 500, 400);
      // set font for fillText()
      context.font = "16px Arial";
      context.textAlign = "right";

      // draw X and Y axis
      context.beginPath();
      context.moveTo(GRAPH_LEFT, GRAPH_BOTTOM);
      context.lineTo(GRAPH_RIGHT, GRAPH_BOTTOM);
      context.lineTo(GRAPH_RIGHT, GRAPH_TOP);
      context.stroke();

      // draw reference line
      context.beginPath();
      context.strokeStyle = "#BBB";
      context.moveTo(GRAPH_LEFT, GRAPH_TOP);
      context.lineTo(GRAPH_RIGHT, GRAPH_TOP);
      // draw reference value for hours
      context.fillText("128", GRAPH_RIGHT + 40, GRAPH_TOP + 5);
      context.fillText("-128", GRAPH_RIGHT + 40, GRAPH_BOTTOM + 5);
      context.stroke();

      // draw reference line
      context.beginPath();
      context.moveTo(GRAPH_LEFT, (GRAPH_HEIGHT) / 4 * 3 + GRAPH_TOP);
      context.lineTo(GRAPH_RIGHT, (GRAPH_HEIGHT) / 4 * 3 + GRAPH_TOP);
      // draw reference value for hours
      context.fillText("-64", GRAPH_RIGHT + 40, (GRAPH_HEIGHT) / 4 * 3 + GRAPH_TOP + 5);
      context.stroke();

      // draw reference line
      context.beginPath();
      context.moveTo(GRAPH_LEFT, (GRAPH_HEIGHT) / 2 + GRAPH_TOP);
      context.lineTo(GRAPH_RIGHT, (GRAPH_HEIGHT) / 2 + GRAPH_TOP);
      // draw reference value for hours
      context.fillText("0", GRAPH_RIGHT + 40, (GRAPH_HEIGHT) / 2 + GRAPH_TOP + 5);
      context.stroke();

      // draw reference line
      context.beginPath();
      context.moveTo(GRAPH_LEFT, (GRAPH_HEIGHT) / 4 + GRAPH_TOP);
      context.lineTo(GRAPH_RIGHT, (GRAPH_HEIGHT) / 4 + GRAPH_TOP);
      // draw reference value for hours
      context.fillText("64", GRAPH_RIGHT + 40, (GRAPH_HEIGHT) / 4 + GRAPH_TOP + 5);
      context.stroke();

      // draw titles
      // context.fillText("Day of the week", GRAPH_RIGHT / 3, GRAPH_BOTTOM + 50);
      // context.fillText("Hours", GRAPH_RIGHT + 30, GRAPH_HEIGHT / 2);

      context.beginPath();
      context.lineJoin = "round";
      context.strokeStyle = "black";
      //
      context.moveTo(GRAPH_LEFT, (GRAPH_HEIGHT - (dataArr[0] + 128) / largest * GRAPH_HEIGHT) + GRAPH_TOP);
      // draw reference value for day of the week
      // context.fillText("1", 15, GRAPH_BOTTOM + 25);
      for (let i = 1; i < arrayLen; i++) {
        context.lineTo(GRAPH_RIGHT / arrayLen * i + GRAPH_LEFT, (GRAPH_HEIGHT - (dataArr[i] + 128) / largest * GRAPH_HEIGHT) + GRAPH_TOP);
        // draw reference value for day of the week
        // context.fillText((i + 1).toString(), GRAPH_RIGHT / arrayLen * i, GRAPH_BOTTOM + 25);
      }
      context.stroke();
    }

    onMounted(() => {
      drawGraph([]);
    });
    return { canvasref, canvasWidth, canvasHeight };
  }
  // created () {
  //   Chart.register(...registerables);
  // },
  // mounted () {
  //   const ctx = document.getElementById("platter-chart") as HTMLCanvasElement;
  //   if (ctx) {
  //     myChart = shallowRef(new Chart(ctx, {
  //       type: "line",
  //       data: {
  //         labels: this.chartLabels,
  //         datasets: [
  //           {
  //             label: "test",
  //             data: this.chartData,
  //             borderColor: "#009444"
  //           }
  //         ]
  //       }
  //     }));
  //   }
  // },
  // watch: {
  //   chartData: {
  //     handler (newData, oldData) {
  //       console.log("chart data updated:", newData);
  //       if (myChart.value) {
  //         myChart.value.update();
  //       }
  //     },
  //     deep: true
  //   }
  // },
  // computed: {
  //   chartLabels () {
  //     return Array(this.chartData.length).fill("");
  //   }
  // }
});
