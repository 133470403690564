
import { defineComponent, PropType } from "vue";
import marked from "marked";

export default defineComponent({
  name: "Lessons",
  props: ["lessonMarkdown"],
  emits: ["navigate"],
  data () {
    return {
      hintMarkdown: ""
    };
  },
  methods: {

  },
  computed: {
    compiledMarkdown (): string {
      return marked(this.lessonMarkdown, { gfm: false });
    }
  }
});
